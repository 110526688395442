<template>
    <div>
        <div>
            <div class="my-flex-row">
                <div class="filter-botton" :class="{active: tabIndex == 0}" @click="tabIndex = 0">昨日</div>
                <div class="filter-botton" :class="{active: tabIndex == 1}" @click="tabIndex = 1">本月</div>
                <div class="filter-botton" :class="{active: tabIndex == 2}" @click="tabIndex = 2">上月</div>
                <div class="right-item" @click="definedDateFilter" :class="{active: tabIndex == 3}">
                    <span class="right-item-text" style="">{{calendarOpt.text}}</span>
                    <img style="width: 14px;" src="../../assets/date.png" alt="">
                </div>
            </div>
        </div>
        <div>
            <van-collapse class="collapse-con" :border="false" v-model="activeNames" v-for="(item, i) in list" :key="i">
                <van-collapse-item :name="i" class="my-collase-item">
                    <template #title>
                        <div class="collapse-item-header colorful-text-bar"  :class="'color-style-' + item.bgColor">
                            <div class="text1">{{i + 1}}</div>
                            <div class="text2">{{item.name}}</div>
                            <div class="text3">{{item.score | formatDecimal}}</div>
                        </div>
                    </template>
                    <div>
                        <div class="collapse-item-con">
                            <evaluation-graph :info="item"></evaluation-graph>
                        </div>
                        <div class="flex-row">
                            <div class="blue-link-text" @click="toExceptionDetail(item.code)">查看异常参数</div>
                            <div class="blue-link-text" @click="$router.push({path: '/home/assessRuleDoc', query: {type: 'evaluation'}})">考核规则说明</div>
                        </div>
                    </div>
                </van-collapse-item>
            </van-collapse>
        </div>
        
        <van-calendar v-model="calendarOpt.showCalendar" :max-date="calendarOpt.maxDate" :min-date="calendarOpt.minDate" @confirm="onCalendarConfirm"
         type="range" :lazy-render="false"></van-calendar>
    </div>
</template>

<script>
import {formatDate, getLastMonth} from '@/utils/common'
import {getAccuracyList} from '@/axios/device'
import evaluationGraph from '@/components/evaluationGraph'
export default {
    components: {evaluationGraph},
    data(){
        return {
            tabIndex: 0,
            activeNames: [],
            list: [],
            avg: null,
            calendarOpt: {
                maxDate: new Date(),
                minDate: new Date((new Date().getFullYear() - 1) + '/01/01'),
                showCalendar: false,
                text: "选择时间段",
                startDate: null,
                endDate: null
            },
            keyFields: [
                "data_accuracy", // 数据准确率
                "data_complete", //数据齐全率
                "complance_rate", //工艺合规率
            ],
            oldParams: null
        }
    },
    watch: {
        tabIndex: function(){
            this.getDataList()
        }
    },
    created(){
        this.getDataList()
    },
    methods: {
        toExceptionDetail(_id){
            this.$router.push({path: '/home/evaluationException', query: Object.assign({}, this.oldParams, {companyId: _id})})
        },
        definedDateFilter(){
            this.calendarOpt.showCalendar = true
            this.tabIndex = 3
        },
        onCalendarConfirm(_date){       
            const [start, end] = _date                 
            this.calendarOpt.showCalendar = false
            this.calendarOpt.startDate = formatDate(start)
            this.calendarOpt.endDate = formatDate(end)
            this.calendarOpt.text = formatDate(start, "month") + " - " + formatDate(end, "month")
            this.getDataList()
        },
        _getParams(){
            let _params = {}
            switch(this.tabIndex.toString()){
                case "0": _params = {
                    type: 2,
                    date: formatDate(new Date(new Date() - 1 * 24 * 3600 * 1000))
                }
                break;
                case "1": _params = {
                    type: 1,
                    date: formatDate(new Date()).substr(0,7)
                }
                break;
                case "2": _params = {
                    type: 1,
                    date: getLastMonth()
                };
                break;
                case "3": 
                if(!this.calendarOpt.startDate){
                    return
                }
                _params = {
                    type: 3,
                    endTime: this.calendarOpt.endDate,
                    startTime: this.calendarOpt.startDate
                }
                break;
            }
            return _params
        },
        getDataList(){
            let _params = this._getParams()
            if(!_params){
                return
            }
            this.oldParams = _params
            getAccuracyList(_params).then(res => {
                this.avg = this._calcScoreNum(res.content.group)
                this.list = this._dataHandleSign(res.content.company || [])
            })
        },
        
        _dataHandleSign: function(_list) {
            let sunTotal = _list.length
            _list.forEach(msg => {
                msg.calcNum = {}; //不含百分号的结果，用于计算是否缺角。
                msg.scoreIcon = {}; //true,切，false，不切。

                this._calcScoreNum(msg)

                this.keyFields.forEach(key => {
                    if(key == 'complance_rate'){
                        msg.scoreIcon[key] = this._iconTypeChangeT(msg.calcNum[key])
                    }else{
                        msg.scoreIcon[key] = this._iconTypeChange(msg.calcNum[key])
                    }
                })
                
                // 子公司综合排名百分比颜色设置 (不考虑齐名，总共sunTotal 个名次。)
                if (msg.score >= this.avg.score) {
                    msg.bgColor = "blue";
                } else {
                    var rankPercent = 1 - msg.rank / sunTotal;
                    //根据排名百分比进行颜色区分
                    msg.bgColor = "green";
                    if (rankPercent > 0.1 && rankPercent < 0.3) {
                        msg.bgColor = "yellow";
                    }
                    if (rankPercent < 0.1) {
                        msg.bgColor = "red";
                    }
                }
            })            
            return _list
        },
        // 评分与比率计算
        _calcScoreNum: function(msg){
            msg.calcNum = {}; //不含百分号的结果，用于计算是否缺角
            
            this.keyFields.forEach(key => {                
                if (typeof msg[key] == "object" || msg[key].length == 2) {
                    let _num = (msg[key][0] / msg[key][1] * 100)
                    msg.calcNum[key] = isNaN(_num)? null : _num.toFixed(2)
                }
            })
            return msg
        },
        
        _iconTypeChange: function(_val){
            if(_val == null){
                return null
            }
            if(_val == 100){
                return 'iconType_4'
            }else if(_val >= 90){
                return 'iconType_1'
            }else if(_val >= 80 && _val < 90){
                return 'iconType_2'
            }else if(_val < 80){
                return 'iconType_3'
            }
        },
        _iconTypeChangeT: function(_val){
            if(_val == null){
                return null
            }

            if(_val == 100){
                return 'iconType_4'
            }else if(_val >= 95){
                return 'iconType_1'
            }else if(_val >= 80 && _val < 95){
                return 'iconType_2'
            }else if(_val < 80){
                return 'iconType_3'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .my-flex-row{
        display: flex;
        margin: 10px 20px 0;
        font-size: 12px;
        align-items: center;
        .filter-botton{
            font-size: 12px;
        }
        >.right-item{
            flex-grow: 1;
            text-align: right;
        }
    }

    .right-item{
        .right-item-text{
            vertical-align: top;
            color:#bfbfbf;
            margin-right: 5px;
            line-height: 18px;
        }
        &.active{
            .right-item-text{
                background: linear-gradient(135deg, #73b9fc 0%, #3f83f8 100%);
                color: #fff;
                border-radius: 22px;
                padding: .5rem 1.25rem;
            }
        }
    }
</style>
<template>
        <svg viewbox="0 0 300 200" xmlns="http://www.w3.org/2000/svg" width="300" height="200">
            <image xlink:href="../assets/画像图-底部.png" x="12.5" y="140" width="275" height="70"></image>
            <polygon stroke-linejoin="round" stroke-width="10" :fill="'url(#' + info.bgColor + '_linear)'"
             :stroke="'url(#' + info.bgColor + '_linear)'" points="87 153, 150 52, 213 153"></polygon>
            <image v-if="info.scoreIcon.data_complete != null" :xlink:href="iconScore(info.scoreIcon.data_complete)" x="142" y="60"></image>
            <image v-if="info.scoreIcon.data_accuracy != null" :xlink:href="iconScore(info.scoreIcon.data_accuracy)" x="92" y="138"></image>
            <image v-if="info.scoreIcon.complance_rate != null" :xlink:href="iconScore(info.scoreIcon.complance_rate)" x="194" y="138"></image>
            <text x="130" y="110" fill="#fff" style="font-size: 14px;font-weight: bold;">{{info.score | formatDecimal(2)}}</text>
            <text x="128" y="127" fill="#FFFFFF99" style="font-size: 12px;">评价得分</text> 
            <g>
                <text x="125" y="24" fill="#0E9F6E" style="font-size: 14px;font-weight: bold;">{{info.calcNum.data_complete | formatDecimal(2, '%')}}</text>
                <text x="120" y="40" fill="#8C8C8C" style="font-size: 12px;">数据齐全率</text>
                
                <text x="225" y="125" fill="#0E9F6E" style="font-size: 14px;font-weight: bold;">{{info.calcNum.complance_rate | formatDecimal(2, '%')}}</text>
                <text x="220" y="142" fill="#8C8C8C" style="font-size: 12px;">工艺合规率</text>
                
                <text x="25" y="125" fill="#0E9F6E" style="font-size: 14px;font-weight: bold;">{{info.calcNum.data_accuracy | formatDecimal(2, '%')}}</text>
                <text x="20" y="142" fill="#8C8C8C" style="font-size: 12px;">数据准确率</text>
            </g>            
        </svg>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: ()=>{}
        }
    },
    methods: {
        iconScore: function(val){
            switch(val){
                case 'iconType_1': return require("../assets/图标-白-正确.png"); break;
                case 'iconType_2': return require("../assets/图标-白-问号.png"); break;
                case 'iconType_3': return require("../assets/图标-白-错误.png"); break;
                case 'iconType_4': return require("../assets/图标-白-感叹号.png"); break;
            }
        }   
    }
}
</script>